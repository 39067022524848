import React, { useEffect, useState } from "react";
import './Coupons.css';
import MainWrapper from "../../components/layout/layout";
import { Navigate, useLoaderData } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Barcodes } from "../../components/barcodes/Barcodes";
import { QRcodes } from "../../components/qrcodes/QRcodes";
import Spinner from 'react-bootstrap/Spinner';
import { deleteCoupon, getCoupons } from "../../services/coupon";
import { Coupon } from "../../components/coupon/Coupon";
import Skeleton from '@mui/material/Skeleton';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DeleteModal } from "../../components/modal/DeleteModal";
import couponimg from "../../assets/images/Bottle.png";
import couponbarcode from "../../assets/images/coupon-barcode.png";
import { Row, Col, Button } from 'react-bootstrap';

function Coupons() {
  const loader = useLoaderData()
  const data = JSON.parse(loader)
  const [coupons, setCoupons] = useState(null)
  const [loading, setLoading] = useState(false)
  const [modalShow, setModalShow] = useState(false);
  const [deleteManufacturerId, setDeleteManufacturerId] = useState(null);
  const [deleteCouponId, setDeleteCouponId] = useState(null);

  useEffect(() => {
    document.title = "User Coupons"

    return async () => {
      setLoading(true)

      if (data === null) {
        return;
      }
      const res = await getCoupons(data?.email)

      if (res === null) {
        setCoupons([])
        setLoading(false)
        alert("something went wrong, please try later")
        return;
      }

      setCoupons(res)
      setLoading(false)
      return;;
    }
  }, [])

  const handleCouponDelete = async (manufacturer_id, coupon_id) => {
    setDeleteManufacturerId(manufacturer_id)
    setDeleteCouponId(coupon_id)
    setModalShow(true)
    return;
  }

  const confirmCouponDelete = async () => {
    const res = await deleteCoupon(deleteManufacturerId, deleteCouponId)

    if (res === null) {
      alert("something went wrong, please try later")
      return;
    }

    setCoupons((coupons) => coupons.filter(coupon => coupon.coupon_id !== deleteCouponId))
    return;
  }

  if (loader === null) {
    return (
      <div className="loaderParent">
        <Spinner animation="border" variant="primary" />
        <Navigate to="/login/coupons" replace={true} />
      </div>
    )
  }

  return (
    <MainWrapper>
      <div className="content-container">
        <Grid container >
          <Grid item xs={12}>
            <div className="tabs">
              <Tabs
                defaultActiveKey="barcode"
                id="justify-tab-example"
              >
                <Tab className="tab" eventKey="barcode" title="Barcode">
                  <Barcodes />
                </Tab>
                <Tab className="tab" eventKey="qrcode" title="QR code">
                  <QRcodes />
                </Tab>
                <Tab className="tab" eventKey="coupon" title="Coupon">
                  {/*<Grid container spacing={2}>
                    <Grid item md={6}>
                      <div>
                        <div className="new-coupon">
                          <div className="coupon-head">
                            <Row>
                              <Col md={12}>
                                <h6>
                                  LIMIT ONE COUPON PER VISIT | 
                                  MANUFACTURER’S COUPON | 
                                  START DATE 09-01-19 | 
                                  EXPIRATION DATE 09-30-19 OFFER VALID ONLY IN THE STATE OF FLORIDA.
                                </h6>
                              </Col>
                            </Row>
                          </div>
                          <div className="coupon-content">
                            
                            <Row>
                              <Col sm={5}>
                                <h1>SAVE $5 NOW</h1>
                              </Col>
                              <Col sm={7}>
                                <h3>on ONE (1) 750ml of Sagamore Spirit®Signature Rye Whiskey.</h3>
                              </Col>
                              <Col sm={3}><img className="proimg" src={couponimg} /></Col>
                              <Col sm={9}>
                                <p>
                                  <b>TO THE CONSUMER :</b>
                                  <ol>
                                    <li>This coupon is good for a $5.00 savings at the time of purchase for ONE (1) 750ml of Sagamore Spirit® 
                                    Signature Rye Whiskey.</li>
                                    <li>Consumer pays state sales tax, if applicable.</li>
                                    <li>Limit one (1) coupon per customer.</li>
                                    <li>This coupon may not be sold, doubled, transferred or reproduced. Any other use constitutes fraud.</li>
                                    <li>This offer cannot be used in conjunction with any other offers.</li>
                                    <li>Valid for residents of FL who are at least 21 years of age or older at time of purchase.</li>
                                    <li>Void where taxed, restricted or prohibited by state law.</li>
                                  </ol>
                                </p>

                                <p><b>TO THE RETAILER :</b>CSM Redemptions, Inc. will reimburse you for the face value of this coupon plus 8¢ handling provided you and the customer comply with the terms of this offer. Any other application constitutes fraud. Coupon is non-transferable, non-assignable and non-reproducible. Consumer must pay any deposit or sales tax. Cash value 1/20 cents. To redeem coupons, mail to $5 Sagamore In-Ad, Offer #600400, 31103 Rancho Viejo Rd. D-2111, San Juan Capistrano, CA 92675. All coupons must be received by 12/31/19. After 12/31/19 redemption requests will not be responded to.</p>
                                <Row>
                                  <Col>
                                    <img className="coupon-barcode" src={couponbarcode} />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <p>Savour our Spirit Responsibly ©2019 Sagamore Whiskey LLC Baltimore, MD</p>
                          </div>
                        </div>

                        <div className="button text-center">
                          <Button
                            variant="outlined"
                            color="primary"
                            aria-label="download"
                          >
                            Download
                          </Button>
                          <Button
                            variant="outlined"
                            color="error"
                            aria-label="delete"
                          >
                            Delete
                          </Button>
                        </div>

                        <div className="new-coupon-design">
                          <div className=""></div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>*/}
                  <Grid container spacing={2}>
                    {loading ? <Skeleton variant="rounded" width={390} height={120} /> :
                      coupons?.length > 0 ? coupons?.map((coupon, id) => {
                        return (
                          <Grid item md={6} key={id}><Coupon data={coupon} handleCouponDelete={handleCouponDelete} /></Grid>
                        )
                      }) : <h4 className="mb-0">Coupon is not available</h4>
                    }
                  </Grid>
                  {/*<div className="couponsContainer">
                    {loading ? <Skeleton variant="rounded" width={390} height={120} /> :
                      coupons?.length > 0 ? coupons?.map((coupon, id) => {
                        return (
                          <div className="coupon" key={id}>
                            <Coupon data={coupon} handleCouponDelete={handleCouponDelete} />
                          </div>
                        )
                      }) : <h4 className="mb-0">Coupon is not available</h4>
                    }
                  </div>*/}
                </Tab>
              </Tabs>
            </div>
          </Grid>
        </Grid>

        {/* delete modal */}
        <DeleteModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          header="Delete coupon"
          body="Delete coupon? This can't be undo."
          confirmDelete={confirmCouponDelete} />
      </div>
    </MainWrapper>
  );
}

export default Coupons;
