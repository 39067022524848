import React from "react";
import "./Coupon.css";
import Button from "@mui/material/Button";
import { Row, Col } from "react-bootstrap";
import DOMPurify from "dompurify";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const Coupon = ({ data, handleCouponDelete }) => {
  const {
    barcode_qr_code,
    offer_detail,
    expiry_date,
    product,
    coupon_id,
    manufacturer_id,
  } = data;

  const downloadPDF = async () => {
    try {
      const element = document.getElementById(`coupon-${coupon_id}`);
      if (!element) {
        console.error("Element not found!");
        return;
      }

      const canvas = await html2canvas(element, { scale: 2, useCORS: true });
      const imgData = canvas.toDataURL("image/jpeg", 1.0);

      const contentWidth = canvas.width;
      const contentHeight = canvas.height;
      const dpi = 96;
      const widthInInches = contentWidth / dpi;
      const heightInInches = contentHeight / dpi;

      const pdf = new jsPDF({
        unit: "in",
        format: [widthInInches, heightInInches],
        orientation: "landscape",
      });

      pdf.addImage(imgData, "JPEG", 0, 0, widthInInches, heightInInches);
      pdf.save(`coupon-${coupon_id}.pdf`);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  return (
    // <div className="couponContainer">
    //   <div className="imageContainer">
    //     <img className="productImage" src={product?.product_images[0]?.image_url} />
    //   </div>
    //   <div className="textContainer">
    //     <h6 className="productName">{product?.name}</h6>
    //     <div className="expiryContainer">
    //       <CalendarMonthIcon fontSize="small" />
    //       <p className="expiryDate">{expiry_date ? expiry_date : "N/A"}</p>
    //     </div>
    //   </div>
    //   <div className="barcodeContainer">
    //     <div className="barcodeParent">
    //       <img className="barcodeImage" src={barcode_qr_code?.image_url} alt="barcode" />
    //     </div>
    //     <div className="offerParent">
    //       <p className="expiryDate">Amount saved:</p>
    //       <p className="offerAmount">{offer_detail ? offer_detail : "N/A"}</p>
    //     </div>
    //   </div>
    //   {/* <div className="couponOverlay"></div> */}
    //   <div className="button text-center ">
    //     <Button variant="outlined" color="error" aria-label="delete" onClick={() => handleCouponDelete(manufacturer_id, coupon_id)}>
    //       Delete
    //     </Button>
    //   </div>
    // </div>
    <>
      <div>
        <div id={`coupon-${coupon_id}`} className="new-coupon">
          <div className="coupon-head">
            <Row>
              <Col md={12}>
                <h6>{product?.header_text?.toUpperCase()}</h6>
              </Col>
            </Row>
          </div>
          <div className="coupon-content">
            <Row>
              <Col sm={5}>
                <h1>SAVE {offer_detail} NOW</h1>
              </Col>
              <Col sm={7}>
                <h3>{product?.name}</h3>
              </Col>
              <Col sm={3}>
                <img
                  className="proimg"
                  src={product?.product_images[0].image_url}
                  crossOrigin="anonymous"
                />
              </Col>
              <Col sm={9}>
                <p>
                  <b>TO THE CONSUMER :</b>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(product?.customer_pointers),
                    }}
                  ></span>
                </p>

                <p>
                  <b>TO THE RETAILER :</b>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(product?.retailer_text),
                    }}
                  ></span>
                </p>
                <Row>
                  <Col>
                    <img
                      className="coupon-barcode"
                      src={barcode_qr_code?.image_url}
                      crossOrigin="anonymous"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <p>{product?.footer_text}</p>
          </div>
        </div>

        <div className="button text-center">
          <Button
            variant="outlined"
            color="primary"
            aria-label="download"
            // onClick={() => handleCouponDownload(coupon_id)}
            onClick={downloadPDF}
          >
            Download
          </Button>
          <Button
            variant="outlined"
            color="error"
            aria-label="delete"
            onClick={() => handleCouponDelete(manufacturer_id, coupon_id)}
          >
            Delete
          </Button>
        </div>

        <div className="new-coupon-design">
          <div className=""></div>
        </div>
      </div>
    </>
  );
};
