import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"
import Login from '../pages/auth/login';
import Register from '../pages/auth/registration';
import BarcodeExpandedStacked from '../pages/barcodeExpandedStacked/barcodeExpandedStacked';
import CompanyPrefixes from '../pages/companyPrefixes/companyPrefixes';
import QrCode from '../pages/qrCode/qrCode';
import ForgotPassword from '../pages/auth/forgot-password';
import ResetPassword from '../pages/auth/reset-password';
import Coupons from '../pages/coupons/Coupons';
import Landing from '../pages/landing/Landing';
import { ProductCategory } from '../pages/productCategory/ProductCategory';
import { Manufacturers } from '../pages/manufacturers/Manufacturers';
import { ManufacturerCoupon } from "../pages/manufacturerCoupon/ManufacturerCoupon"
import { Profile } from '../pages/profile/Profile';
import { CouponUsage } from '../pages/coupon-usage/CouponUsage';
import { NotFound } from '../pages/notFound/NotFound';
import { CreateCoupon } from '../pages/createCoupon/CreateCoupon';

const getUser = () => {
  return localStorage.getItem("user");
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/login/:return",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/barcode/:return",
    element: <BarcodeExpandedStacked />,
    loader: getUser,
  },
  {
    path: "/qrcode/:return",
    element: <QrCode />,
    loader: getUser,
  },
  {
    path: "/companyprefixes/:return",
    element: <CompanyPrefixes />,
    loader: getUser,
  },
  {
    path: "/productcategory/:return",
    element: <ProductCategory />,
    loader: getUser,
  },
  {
    path: "/coupons/:return",
    element: <Coupons />,
    loader: getUser,
  },
  {
    path: "/manufacturers/:return",
    element: <Manufacturers />,
    loader: getUser,
  },
  {
    path: "/manufacturer-coupon/:email",
    element: <ManufacturerCoupon />,
    loader: getUser,
  },
  {
    path: "/profile/:return",
    element: <Profile />,
    loader: getUser,
  },
  {
    path: "/coupons-usage/:userId",
    element: <CouponUsage />,
    loader: getUser,
  },
  {
    path: "/create-coupon",
    element: <CreateCoupon />,
    loader: getUser,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

function App() {

  document.addEventListener("wheel", function (event) {
    if (document.activeElement.type === "number") {
      document.activeElement.blur();
    }
  });

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
