// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.updateImageContainer {
  position: relative;
  display: inline-block;
  left: 50%;
  margin-bottom: 20px;
  transform: translateX(-50%);
}
.updateImageContainer button {
  right: 20px;
  bottom: 30px;
  color: #FFFFFF;
  background: #0d6efd;
  position: absolute;

}
.updateProfileImage {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  object-fit: fill;
  border: #ccc solid 10px;
}

.updateBtnContainer {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  flex-wrap: wrap;
}
`, "",{"version":3,"sources":["webpack://./src/components/profile/updateUser/UpdateUser.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,SAAS;EACT,mBAAmB;EACnB,2BAA2B;AAC7B;AACA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;EACd,mBAAmB;EACnB,kBAAkB;;AAEpB;AACA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,SAAS;EACT,eAAe;AACjB","sourcesContent":[".updateImageContainer {\n  position: relative;\n  display: inline-block;\n  left: 50%;\n  margin-bottom: 20px;\n  transform: translateX(-50%);\n}\n.updateImageContainer button {\n  right: 20px;\n  bottom: 30px;\n  color: #FFFFFF;\n  background: #0d6efd;\n  position: absolute;\n\n}\n.updateProfileImage {\n  width: 250px;\n  height: 250px;\n  border-radius: 50%;\n  object-fit: fill;\n  border: #ccc solid 10px;\n}\n\n.updateBtnContainer {\n  margin-top: 20px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  gap: 20px;\n  flex-wrap: wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
