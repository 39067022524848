import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { CardItem } from "../card/Card";
import { deleteImages, getImages } from "../../services/image";
import Skeleton from "@mui/material/Skeleton";
import { DeleteModal } from "../modal/DeleteModal";
import "./Barcodes.css";
import { Row, Col } from "react-bootstrap";
import couponimg from "../../assets/images/Bottle.png";
import couponbarcode from "../../assets/images/coupon-barcode.png";

export const Barcodes = () => {
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    return async () => {
      setLoading(true);
      const data = await getImages("Barcode");
      if (data === null) {
        setCoupons([]);
        setLoading(false);
        return;
      }
      setCoupons(data);
      setLoading(false);
    };
  }, []);

  const deleteCoupon = (data) => {
    setData(data);
    setModalShow(true);
    return;
  };

  const confirmDeleteCoupon = async () => {
    console.log("confirmDeleteCoupon: ", data);

    const res = await deleteImages("Barcode", data.name);

    if (res === null) {
      alert("Something went wrong, please try later.");
      return;
    }

    const newData = coupons.filter((item) => item.name !== data.name);
    setCoupons(newData);
    return;
  };

  if (loading) {
    return (
      <Grid item xs={6} md={4} lg={3}>
        <Skeleton variant="rectangular" width={230} height={200} />
      </Grid>
    );
  }

  return (
    <Grid container spacing={1}>
      {coupons?.length !== 0 ? (
        coupons?.map((items, key) => {
          return (
            <CardItem data={items} key={key} deleteCoupon={deleteCoupon} />
          );
        })
      ) : (
        <Grid item xs={6} md={4} lg={3}>
          <h4 className="mb-0">Barcode is not available</h4>
        </Grid>
      )}

      {/* delete modal */}
      <DeleteModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        header="Delete Barcode"
        body="Are you sure you want to delete this barcode? This action cannot be undone."
        confirmDelete={confirmDeleteCoupon}
      />

      {/* <div className="new-coupon">
        <div className="coupon-head">
          <Row>
            <Col md={9}>
              <h6>
                LIMIT ONE COUPON PER VISIT | MANUFACTURER’S COUPON | START DATE
                09-01-19 | EXPIRATION DATE 09-30-19
              </h6>
            </Col>
            <Col md={3}>
              <h6>OFFER VALID ONLY IN THE STATE OF FLORIDA.</h6>
            </Col>
          </Row>
        </div>
        <div className="coupon-content">
          <img className="proimg" src={couponimg} />
          <Row>
            <Col>
              <h1>SAVE $5 NOW</h1>
            </Col>
            <Col>
              <h3>
                on ONE (1) 750ml of Sagamore Spirit® Signature Rye Whiskey.
              </h3>
            </Col>
            <Col sm={12}>
              <p>
                <b>TO THE CONSUMER:</b> 1. This coupon is good for a $5.00
                savings at the time of purchase for{" "}
                <b>ONE (1) 750ml of Sagamore Spirit® Signature Rye Whiskey</b>.
                2. Consumer pays state sales tax, if applicable. 3. Limit one
                (1) coupon per customer. 3. This coupon may not be sold,
                doubled, transferred or reproduced. Any other use constitutes
                fraud. 5. This offer cannot be used in conjunction with any
                other offers. 6. Valid for residents of <b>FL</b> who are at
                least 21 years of age or older at time of purchase. 7. Void
                where taxed, restricted or prohibited by state law. 8. Offer not
                valid for employees of Sagamore Whiskey LLC. or its related
                companies, alcohol beverage wholesale or retail licensees, or
                members of their families or households.{" "}
              </p>
            </Col>
          </Row>
          <img className="coupon-barcode" src={couponbarcode} />
          <Row>
            <Col>
              <p>
                <b>TO THE RETAILER:</b> CSM Redemptions, Inc. will reimburse you
                for the face value of this coupon plus 8¢ handling provided you
                and the customer comply with the terms of this offer. Any other
                application constitutes fraud. Coupon is non-transferable,
                non-assignable and non-reproducible. Consumer must pay any
                deposit or sales tax. Cash value 1/20 cents. To redeem coupons,{" "}
                <b>
                  mail to $5 Sagamore In- Ad, Offer #600400, 31103 Rancho Viejo
                  Rd. D-2111, San Juan Capistrano, CA 92675
                </b>
                . All coupons must be received by <b>12/31/19</b>. After{" "}
                <b>12/31/19</b> redemption requests will not be responded to.
                Invoices proving sufficient product purchases to cover coupons
                presented for payment and/or reports proving consumer redemption
                must be submitted on request. Failure to do so may, at our
                option, void all coupons for which no proof is shown. Use not
                consistent with these terms constitutes fraud and may void all
                coupons submitted. Coupons will be refused if submitted through
                agencies or clearinghouses not approved by us.
              </p>

              <p>
                Savour our Spirit Responsibly ©2019 Sagamore Whiskey LLC
                Baltimore, MD
              </p>
            </Col>
          </Row>
        </div>
      </div> */}
    </Grid>
  );
};
