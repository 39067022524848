// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hr {
  margin: 0;
  padding: 0;
}
.nav-tabs {
  border-bottom: 0;
  margin-bottom: 20px;
}
.tabs {
  /*border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  margin-top: 15px;*/
  margin-right: 40px;
  margin-left: 40px;
  border-bottom: 0;
}
.tab {
/*  border-top: 1px solid #ccc;*/
  padding: 10px;
}
.nav-tabs .nav-link {
  color: #0032a1;
  border-radius: 20px;
  margin-right: 0.5rem;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #FFF;
  background: #0032a1;
  border-color: #0032a1;
  border-width: 1;
}
.loaderParent {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.couponsContainer {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}
.tab-content {
  padding: 10px;
  border-radius: 30px;
  border: #e4e4e4 solid 1px;
}`, "",{"version":3,"sources":["webpack://./src/pages/coupons/Coupons.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;AACZ;AACA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE;;;oBAGkB;EAClB,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;AAClB;AACA;AACA,gCAAgC;EAC9B,aAAa;AACf;AACA;EACE,cAAc;EACd,mBAAmB;EACnB,oBAAoB;AACtB;AACA;EACE,WAAW;EACX,mBAAmB;EACnB,qBAAqB;EACrB,eAAe;AACjB;AACA;EACE,aAAa;EACb,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,eAAe;EACf,SAAS;AACX;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B","sourcesContent":[".hr {\n  margin: 0;\n  padding: 0;\n}\n.nav-tabs {\n  border-bottom: 0;\n  margin-bottom: 20px;\n}\n.tabs {\n  /*border: 1px solid #ccc;\n  border-radius: 10px;\n  padding: 10px;\n  margin-top: 15px;*/\n  margin-right: 40px;\n  margin-left: 40px;\n  border-bottom: 0;\n}\n.tab {\n/*  border-top: 1px solid #ccc;*/\n  padding: 10px;\n}\n.nav-tabs .nav-link {\n  color: #0032a1;\n  border-radius: 20px;\n  margin-right: 0.5rem;\n}\n.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {\n  color: #FFF;\n  background: #0032a1;\n  border-color: #0032a1;\n  border-width: 1;\n}\n.loaderParent {\n  height: 100vh;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.couponsContainer {\n  display: flex;\n  justify-content: flex-start;\n  flex-wrap: wrap;\n  gap: 10px;\n}\n.tab-content {\n  padding: 10px;\n  border-radius: 30px;\n  border: #e4e4e4 solid 1px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
